import React, {createContext, useEffect, useRef, useState} from "react";
import {useLocalStorage} from "./lib/local-storage.hook";
import i18n from "i18next";
import {AppRoom, appData} from "./app.data";
import {convertArrivalDate, convertDepartureDate, day, minArrivalDate, minDepartureDate} from "./lib/utils";
import { createClient } from "@supabase/supabase-js";

export interface AppOrderCustomer {
    fio: string;
    number: string;
    email: string;
    remarks?: string;
}

export interface AppOrderRoom {
    price: number;
    categoryId: string;
    amount: number;
}

export interface AppOrder {
    arrivalDate: Date;
    departureDate: Date;
    customer?: AppOrderCustomer;
    rooms: AppOrderRoom[];
}

export interface DatabaseRoomPrice {
    id: number;
    referenceId: string;
    name: string;
    price: number;
}

export type AppLanguage = "en" | "uk" | "ru";

export type AppContextType = {
    language: AppLanguage;
    switchLanguage: (lang: AppLanguage) => void;
    filterByCategory: (categoryId: string) => void;
    removeCategory: (categoryId: string) => void;
    filteredCategories: string[];
    roomsInfo: AppRoom[];
    order: AppOrder;
    changeArrivalDate: (date: Date) => void;
    changeDepartureDate: (date: Date) => void;
    changeOrderCustomer: (customer?: AppOrderCustomer) => void;
    changeOrderRooms: (rooms: AppOrderRoom[]) => void;
    clearOrder: (saveCustomer: boolean) => void;
    showFullScreenImages: (images: string[], active?: number) => void;
    fullScreenImages: string[];
    pricesLoaded: boolean;
    surcharge: number;
}

const defaultOrder: AppOrder = {
    arrivalDate: convertArrivalDate(new Date()),
    departureDate: convertDepartureDate(new Date()),
    rooms: []
}

const defaultContext: AppContextType = {
    language: 'uk',
    switchLanguage: () => {},
    filterByCategory: () => {},
    removeCategory: () => {},
    filteredCategories: [],
    roomsInfo: [],
    order: defaultOrder,
    changeArrivalDate: _ => {},
    changeDepartureDate: _ => {},
    changeOrderCustomer: _ => {},
    changeOrderRooms: _ => {},
    clearOrder: _ => {},
    showFullScreenImages: _ => {},
    fullScreenImages: [],
    pricesLoaded: false,
    surcharge: 19
}

export const AppContext = createContext<AppContextType>(defaultContext);

export const AppProvider = ({children}: React.PropsWithChildren) => {
    const [language, setLang] = useLocalStorage('lang', defaultContext.language);
    const [filteredCategories, setFilteredCategories] = useLocalStorage('cats', [] as string[]);
    const [order, setOrder] = useLocalStorage('order', defaultOrder);
    const [fullScreenImages, setFullScreenImages] = useState([] as string[]);
    const [roomsInfo, setRoomsInfo] = useState(appData as AppRoom[]);
    const pricesLoaded = useRef(false)
    const [pricesUpdated, setPricesUpdated] = useState(false)
    const [surcharge, setSurcharge] = useState(19)

    const showFullScreenImages = (images: string[], active: number = 0) => {
        const activeImg = images[active];

        if (!activeImg) {
            setFullScreenImages(images)
        } else {
            setFullScreenImages([activeImg, ...images.filter( (_, index) => index !== active)]);
        }
    }

    const changeArrivalDate = (date: Date): void => {
        if (!date) return;

        const min = new Date(new Date().setHours(0,0,0,0));
        if (date > min) {
            order.arrivalDate = date;
            setOrder(order);
        }
    }

    const changeDepartureDate = (date: Date): void => {
        if (!date) return;

        const min = new Date(new Date().setHours(0,0,0,0) + day);
        if (date > min) {
            order.departureDate = date;
            setOrder(order);
        }
    }

    const actualizeDates = () => {
        const curArrival = new Date(order.arrivalDate);
        const curDeparture = new Date(order.departureDate);

        if (curArrival < minArrivalDate) {
            order.arrivalDate = convertArrivalDate(minArrivalDate);
            order.rooms = [];
        }
        if (curDeparture < minDepartureDate) order.departureDate = convertDepartureDate(minDepartureDate);

        setOrder(order);
    }

    const changeOrderCustomer = (customer?: AppOrderCustomer): void => {
        order.customer = customer;
        setOrder(order);
    }
    const changeOrderRooms = (rooms: AppOrderRoom[]): void => {
        order.rooms = rooms ? rooms : [];
        setOrder(order);
    }

    const clearOrder = (saveCustomer = true): void => {
        if (!saveCustomer) order.customer = undefined;
        order.rooms = [];
        order.arrivalDate = convertArrivalDate(minArrivalDate);
        order.departureDate = convertDepartureDate(minDepartureDate);

        setOrder(order);
    }

    function switchLang(lang: AppLanguage, setLangCallback: (lang: AppLanguage) => void) {
        i18n.changeLanguage(lang).then((s) => {
            setLangCallback(lang);
        });
    }

    const switchLangHandler = (lang: AppLanguage) => {
        switchLang(lang, setLang);
    }

    const filterCategory = (categoryId: string): void => {
        if (!categoryId) return;

        if (!filteredCategories.includes(categoryId)) setFilteredCategories(prev => [...prev, categoryId]);
    }

    const removeCategory = (categoryId: string): void => {
        if (!categoryId) return;

        if (filteredCategories.includes(categoryId)) setFilteredCategories(prev => prev.filter( c => c !== categoryId));
    }

    
    const loadPrices = (): void => {

        const supabaseUrl = 'https://pqjeiybjpotvqseslrjk.supabase.co'
        const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBxamVpeWJqcG90dnFzZXNscmprIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMzNDk0OTIsImV4cCI6MjA0ODkyNTQ5Mn0.0bOLqOm4VPHAHzLOdXocwjFg1VK_YTMabUmgA2l7juA'
        const supabase = createClient(supabaseUrl, supabaseKey)

        supabase.from('rooms').select().then( res => {
            console.log(res)
            if (res.status === 200) {
                const prices: DatabaseRoomPrice[] = res.data as DatabaseRoomPrice[]

                if (prices.length > 0) {

                    for (let price of prices) {
                        
                        if (price.referenceId === 'surcharge') {
                            setSurcharge(price.price)
                        }

                        roomsInfo.map( curRoom => {
                            if (curRoom.externalId === price.referenceId) {
                                curRoom.curPrice = price.price
                            }
                        })
    
                    }
    
                    setRoomsInfo(roomsInfo)
                    setPricesUpdated(true)
                }
            }
            
        })

    }

    useEffect(() => {
        actualizeDates();
        switchLang(language, setLang);
        if (!pricesLoaded.current) {
            loadPrices()
            pricesLoaded.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, pricesLoaded]);

    return (
        <AppContext.Provider value={{
            language: language,
            switchLanguage: switchLangHandler,
            filteredCategories: filteredCategories,
            filterByCategory: filterCategory,
            removeCategory: removeCategory,
            roomsInfo: roomsInfo,
            order: order,
            changeArrivalDate: changeArrivalDate,
            changeDepartureDate: changeDepartureDate,
            changeOrderCustomer: changeOrderCustomer,
            changeOrderRooms: changeOrderRooms,
            clearOrder: clearOrder,
            showFullScreenImages: showFullScreenImages,
            fullScreenImages: fullScreenImages,
            pricesLoaded: pricesUpdated,
            surcharge: surcharge
        }}>
            {children}
        </AppContext.Provider>
    )
}